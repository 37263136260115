import React, { createContext, useState, useEffect } from 'react';

export const MessageContext = createContext();
export const ResponseContext = createContext();

//Underでのメッセージやり取り
export const UnderMessageProvider = ({ children }) => {
  const [messagesByUser, setMessagesByUser] = useState({
    user11: [
      { text: "こんにちは！マッチありがとう😊", user: true, date: "2024/01/10" },
      { text: "こちらこそ！こちらも嬉しいです！", user: false, bot: true, date: "2024/01/10" },
      { text: "今度どこかでご飯でもどう？", user: true, date: "2024/01/10" },
      { text: "いいですね！友達も誘って2:2でどうかな？", user: false, bot: true, date: "2024/01/11" },
      { text: "いいね！2:2って気楽で楽しいよね！じゃあ、友達に声かけとく～！", user: true, date: "2024/01/11" },
      { text: "了解です！日程とか決まったら教えてね✨", user: false, bot: true, date: "2024/01/12" },
      { text: "友達一人つかまったんだけど、来週月曜の夜とか都合どう？", user: true, date: "2024/01/13" },
      { text: "OKです！場所とか決めとく？", user: false, bot: true, date: "2024/01/13" },
      { text: "澁谷駅近くでいいかな？適当に居酒屋とかで。", user: true, date: "2024/01/13" },
      { text: "いいね！じゃあ当日よろしくー！", user: false, bot: true, date: "2024/01/14" },
      { text: "おはよう！ごめん、急なんだけど友達が急用で来れなくなっちゃた…", user: true, date: "2024/01/15" },
      { text: "あら、そうなんだ！1人でも大丈夫だけど、気まずくない？", user: false, bot: true, date: "2024/01/15" },
      { text: "俺は全然大丈夫！もしよかったらそのまま2:1で会おう！", user: true, date: "2024/01/15" },
      { text: "OK！じゃあ、3人で会おう！楽しみにしてるね😊", user: false, bot: true, date: "2024/01/15" }
    ],
    user12: [
      { text: "美月です、よろしくお願いします！", user: false, bot: true ,date: "2024/01/13" },
      { text: "よろしく！", user: true ,date: "2024/01/13"},
      { text: "今日は天気がいいね。", user: false, bot: true ,date: "2024/01/13"}
    ]
  });
  

  const setMessagesForUser = (userId, newMessages) => {
    setMessagesByUser((prevMessagesByUser) => ({
      ...prevMessagesByUser,
      [userId]: newMessages,
    }));
  };

  return (
    <MessageContext.Provider value={{ messagesByUser, setMessagesForUser }}>
      {children}
    </MessageContext.Provider>
  );
};



