import React, { useState, useEffect, useRef } from 'react';
import './ObaAssistant.css';

const ObaAssistant = () => {
  const [isListening, setIsListening] = useState(false);
  const [isObaActive, setIsObaActive] = useState(false);
  const [messageLog, setMessageLog] = useState([]);
  const recognitionRef = useRef(null);

  // ---- カタカナ変換関数 ----
  const toKatakana = (str) => {
    // ひらがなをカタカナに変換
    const hiraToKana = str.replace(/[\u3041-\u3096]/g, (ch) => 
      String.fromCharCode(ch.charCodeAt(0) + 0x60)
    );
    
    // 漢字やアルファベットなどをカタカナ表記に変換
    return hiraToKana.replace(/[一-龯ぁ-んa-zA-Z]/g, (char) => {
      if (/[\u4E00-\u9FFF]/.test(char)) {
        // 漢字は直接対応できないため、代替処理（外部ライブラリなどの使用も検討）
        return ""; // ダミー文字（必要なら実際の読みを実装）
      }
      if (/[a-zA-Z]/.test(char)) {
        // アルファベットをカタカナに変換
        return String.fromCharCode(char.toUpperCase().charCodeAt(0) + 0xFF00 - 0x20);
      }
      return char;
    });
  };
  

  // ---- ① Web Speech API の存在確認 ----
  useEffect(() => {
    const { webkitSpeechRecognition, SpeechRecognition } = window;
    if (!webkitSpeechRecognition && !SpeechRecognition) {
      console.error("Web Speech API not supported in this browser.");
      return;
    }
    // Chrome系は webkitSpeechRecognition, Firefox系は SpeechRecognition と使い分け
    const SpeechRecognitionClass = webkitSpeechRecognition || SpeechRecognition;
    recognitionRef.current = new SpeechRecognitionClass();
    recognitionRef.current.lang = 'ja-JP';  // 音声認識の言語設定
    recognitionRef.current.continuous = true; // 連続で拾いたい場合
    recognitionRef.current.interimResults = false; // 中間結果を受け取るかどうか

    // ---- ② 音声認識のイベント設定 ----
    recognitionRef.current.onresult = (event) => {
        let transcript = event.results[event.results.length - 1][0].transcript.trim();
        console.log("User said (before conversion):", transcript);
  
        // カタカナに変換
        transcript = toKatakana(transcript);
        console.log("User said (after conversion):", transcript);
        // リスニングを停止
        recognitionRef.current.stop();
        setIsListening(false);
  
        // 条件によるアラート表示
        if (transcript.includes("オバ") || transcript.includes("ヘイ")) {
          alert("Hey Oba が検知されました！");
          setIsObaActive(true);
        } else {
          alert(`認識された音声: ${transcript}`);
        }
  
        // ログに追加
        setMessageLog(prev => [...prev, { role: "user", text: transcript }]);
      };
  
      recognitionRef.current.onerror = (err) => {
        console.error("Speech recognition error:", err);
      };
  
      recognitionRef.current.onend = () => {
        console.log("Speech recognition ended");
      };
    }, []);

  // ---- ③ リスニングの開始/停止 ----
  const handleStartListening = () => {
    if (!recognitionRef.current) return;
    recognitionRef.current.start();
    setIsListening(true);
  };

  const handleStopListening = () => {
    if (!recognitionRef.current) return;
    recognitionRef.current.stop();
    setIsListening(false);
  };

  // ---- ④ ユーザーが選択肢をタップしたとき ----
  const handleUserChoice = (choice) => {
    // choiceを元に、obaが答えるロジックを作る
    let obaReply = "";
    if (choice === "option1") {
      obaReply = "オプション1 ですね。かしこまりました！";
    } else if (choice === "option2") {
      obaReply = "オプション2 ですね。了解です！";
    } else {
      obaReply = "その選択肢はまだ知らないんです…";
    }

    // messageLogに、ユーザーの選択とobaの回答を追加
    setMessageLog(prev => [
      ...prev,
      { role: "user", text: `【選択】${choice}` },
      { role: "oba", text: obaReply },
    ]);
  };

  return (
    <div style={{ border: "1px solid #ccc", padding: "1rem", margin: "1rem" }}>
      <h2>Oba Assistant</h2>

      {/* リスニング状態の切り替えボタン */}
      {!isListening ? (
        <button onClick={handleStartListening}>「Hey Oba」をリスニング開始</button>
      ) : (
        <button onClick={handleStopListening}>リスニング停止</button>
      )}

      {/* OBA エージェントがアクティブになったら、選択肢UIを表示 */}
      {isObaActive && (
        <div style={{ marginTop: "1rem" }}>
          <p>Obaが起動しました！聞きたい内容を選択してください。</p>
          <div style={{ display: "flex", gap: "1rem" }}>
            <button onClick={() => handleUserChoice("option1")}>オプション1</button>
            <button onClick={() => handleUserChoice("option2")}>オプション2</button>
          </div>
        </div>
      )}

      {/* ログ表示 (デバッグ用) */}
      <div style={{ marginTop: "1rem", maxHeight: "200px", overflow: "auto" }}>
        {messageLog.map((msg, idx) => (
          <div key={idx}>
            <strong>{msg.role === "user" ? "👤" : "🤖"}:</strong> {msg.text}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ObaAssistant;
