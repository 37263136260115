import React, { useState, useEffect,useRef } from "react";
import "./first-calling.css"; // 新しいCSSファイルをインポート

const FirstCalling = ({ onEndCall }) => {
  const [callState, setCallState] = useState("C-overlay"); // 初期状態はオーバーレイ
  const [elapsedTime, setElapsedTime] = useState(0); // 経過時間を管理
  const [endMessage, setEndMessage] = useState(""); // 終了メッセージを管理
  const displayedMessageRef = useRef(""); // 表示中の文字列を管理
  const [displayedMessage, setDisplayedMessage] = useState(""); // 再描画トリガー用
  const [showedEndMessage, setShowedEndMessage] = useState(false); // メッセージ表示済みフラグ
  const audioRef = useRef(null);
  const vibrationRef = useRef(null);

  useEffect(() => {
    if (callState === "incoming") {
      // 振動音を再生
      if (vibrationRef.current) {
        vibrationRef.current.play().catch((err) =>
          console.error("Vibration audio playback failed:", err)
        );
      }
    } else if (callState === "in-call") {
      // 通話音を再生
      if (audioRef.current) {
        audioRef.current.play().catch((err) =>
          console.error("Audio playback failed:", err)
        );
      }
    }
  }, [callState]);

  const handleOverlayClick = () => {
    setCallState("incoming"); // 着信状態に移行
  };

  useEffect(() => {
    let timer;
    if (callState === "in-call") {
      timer = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1); // 1秒ごとに時間を更新
      }, 1000);

      // 音声の再生をトリガー
      if (audioRef.current) {
        audioRef.current.play().catch((err) => console.error("Audio playback failed:", err));
      }
    }
    return () => clearInterval(timer); // クリーンアップ
  }, [callState]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  //文字アニメーション
  const animateMessage = (message) => {
    let index = 0;
    displayedMessageRef.current = ""; // 初期化
    const interval = setInterval(() => {
      displayedMessageRef.current += message[index]; // 文字を追加
      setDisplayedMessage(displayedMessageRef.current); // 再描画
      index++;
      if (index === message.length) {
        clearInterval(interval);
      }
    }, 80); // 1文字あたりの速度
  };

  const handleEndButtonClick = () => {
    if (showedEndMessage) {
      handleEndCall();
    } else {
      const message = "･･･やるべきことはわかってる？わかってるなら、切ってもいいよ";
      setEndMessage(message);
      animateMessage(message);
      setShowedEndMessage(true);
    }
  };

  const handleEndCall = () => {
    setCallState("ended");
    onEndCall(); // 呼び出し元で処理
  };

  return (
    <div
      className="phone-call-container"
      style={{
        background:
        callState === "C-overlay"
          ? "rgba(255, 255, 255, 0)"
          : "radial-gradient(circle, rgba(70, 50, 30, 0.8), rgba(20, 10, 5, 1))"      
      }} 
    >
      {callState === "C-overlay" && (
        <div className="call-overlay" onClick={handleOverlayClick}></div>
      )}

      {callState === "incoming" && (
        <div className="call-ui incoming">
          <audio ref={vibrationRef} autoPlay src="/sounds/vibration.mp3" />
          <p className="caller-name">非通知設定</p>
          <p className="animated-text">
            {displayedMessage.split("").map((char, index) => (
              <span key={index} style={{ animationDelay: `${index * 0.03}s` }}>
                {char}
              </span>
            ))}
          </p>
          <div className="call-buttons">
{/* テンプレートリテラル "incoming"がtrueの場合 →<div class="call-end-container incoming"> falseの場合 → <div class="call-end-container in-call"> */}
            <div className={`call-end-container ${callState === "incoming" ? "incoming" : "in-call"}`}>
              <button className="call-end" onClick={handleEndButtonClick}></button>
            </div>
            <button className="call-answer" onClick={() => setCallState("in-call")}>
            </button>
          </div>
        </div>
      )}

      {callState === "in-call" && (
        <div className="call-ui in-call">
        <audio ref={audioRef} autoPlay src={`${process.env.PUBLIC_URL}/sounds/onsei.aac`} />
        <div className="call-status-bar">
          <p className="caller-name">非通知設定</p>
          <p className="call-timer">{formatTime(elapsedTime)}</p>
        </div>
          <div class="call-options">
            <button class="option mute">
                <div class="icon"></div>
                <span>消音</span>
            </button>
            <button class="option keypad">
                <div class="icon"></div>
                <span>キーパッド</span>
            </button>
            <button class="option speaker">
                <div class="icon"></div>
                <span>スピーカー</span>
            </button>
            <button class="option add-call">
                <div class="icon"></div>
                <span>通話を追加</span>
            </button>
            <button class="option facetime">
                <div class="icon"></div>
                <span>FaceTime</span>
            </button>
            <button class="option contacts">
                <div class="icon"></div>
                <span>連絡先</span>
            </button>
         </div>
         <button className="call-end" onClick={handleEndCall}></button>
        </div>
      )}
    </div>
  );
};

export default FirstCalling;
