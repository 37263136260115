import React, {useEffect,useState } from 'react';
import './App.css';
import FirstCalling from "./first-calling";
import Match from './Match';
import MatchID from './MatchApp/Match-ID';
import MatchChat from './MatchApp/Match-chat';
import MatchSwipe from './MatchApp/Match-swipe';
import Camera from './Camera.js'; 
import Calender from './calender.js';
import BankApp from './BankApp.js'; 
import PhotoApp from './PhotoApp.js';
import MapApp from './MapApp';
import YouTubeStudio from './YouTubeStudio.js';
import ObaAssistant from './ObaAssistant';
import { UnderMessageProvider } from './MessageContext.js'; 
import { AppProvider } from './MatchApp/AppContext.js';
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Html } from '@react-three/drei'
import * as THREE from 'three'
import { useContextBridge } from '@react-three/drei'

// 画像を参照
// const backgroundImage = `${process.env.PUBLIC_URL}/images/background.webp`;
const photosIcon = `${process.env.PUBLIC_URL}/images/photos-icon.png`;
const CalenderIcon = `${process.env.PUBLIC_URL}/images/calender-icon.png`;
const cameraIcon = `${process.env.PUBLIC_URL}/images/camera-icon.png`;
const underIcon = `${process.env.PUBLIC_URL}/images/swipe.png`;
const bankIcon = `${process.env.PUBLIC_URL}/images/bank-icon.png`;
const YoutubeIcon = `${process.env.PUBLIC_URL}/images/Youtube-icon.png`;


const applications = [
  { name: '写真', id: 'photo', icon: photosIcon },
  // { name: 'カレンダー', id: 'calender', icon: CalenderIcon },
  { name: 'YS', id: 'youtube-ST', icon: YoutubeIcon },  
  // { name: 'カメラ', id: 'camera', icon: cameraIcon },
  { name: 'under', id: 'under', icon: underIcon },
  { name: 'BANK', id: 'bank', icon: bankIcon }, 
  // {name: 'Map', id: 'google-map', icon: `${process.env.PUBLIC_URL}/images/map-icon.png` }, 
];

// 縦向きバッテリーアイコンのSVGコンポーネント
const BatteryIcon = ({ level }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={`Battery level: ${level}%`}
    >
      {/* バッテリーヘッド部分（上部） */}
      <rect x="4.5" y="1" width="8" height="3" fill="#A9A9A9" />  {/* 薄いグレーのヘッド部分 */}
      
      {/* バッテリーの充電されていない部分（全体背景） */}
      <rect x="1" y="4" width="15" height="18" rx="2" ry="2" fill="#A9A9A9" /> {/* 薄いグレーの背景 */}
      
      {/* バッテリー充電レベル（濃いグレーで下から上に充電） */}
      <rect
        x="1"
        y={`${22 - (16 * level) / 100}`} // レベルに応じて位置を設定（下から充電）
        width="15"
        height={`${(16 * level) / 100}`} // 残量に応じた高さ
        fill="#384043" // 充電されている部分を濃いグレー
      />
    </svg>
  );
};
//Wi-Fiアイコン
const WifiIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="WiFi"
  >
    {/* 最下段の点 */}
    <circle cx="12" cy="18" r="1" fill="#333" />
    
    {/* 下から1番目の曲線 */}
    <path
      d="M9 15c1.5-1.5 4-1.5 5.5 0"
      stroke="#333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    {/* 下から2番目の曲線 */}
    <path
      d="M6 12c3-3 8-3 11 0"
      stroke="#333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    {/* 下から3番目の曲線 */}
    <path
      d="M3 9c5-5 13-5 18 0"
      stroke="#333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// FFTのサイズ（波形の棒の数に影響。増やすと波形が詳細に、減らすと粗くなる。）
const FFT_SIZE = 2 ** 13; 

// カレンダーウィジェットコンポーネント
const CalendarWidget = ({ AppClick }) => {
  const today = new Date();
  const day = today.getDate();
  const weekday = today.toLocaleDateString('ja-JP', { weekday: 'short' });
  const tasks = ['タスク1', 'タスク2']; // 仮のタスクデータ

  return (
    <div className="calendar-widget" onClick={() => AppClick({ id: 'calender' })}>
      <div className="calendar-widget-header">
        <span className="calendar-widget-weekday">{weekday}</span>
        <span className="calendar-widget-date">{day}</span>
      </div>
      <div className="calendar-widget-tasks">
        {tasks.map((task, index) => (
          <div key={index} className="calendar-widget-task">
            {task}
          </div>
        ))}
      </div>
    </div>
  );
};

// MAPウィジェットコンポーネント
const GoogleMapWidget = ({ AppClick }) => {
  return (
    <div className="google-map-widget" onClick={() => AppClick({ id: 'google-map' })}>
      <img
        src={`${process.env.PUBLIC_URL}/images/mapwidget.png`} // 画像パスを指定
        alt="Google Map Icon"
        className="google-map-widget-image"
      />
    </div>
  );
};


function App({ signOut, user }) {
  // 1) 360テクスチャ読み込み用のstate・effect
  const [texture, setTexture] = useState(null)
  const [isInteracting, setIsInteracting] = useState(false); // 360度回転状態を追加
  useEffect(() => {
    const loader = new THREE.TextureLoader();
    loader.load(
      'https://d15k62eom0hyt7.cloudfront.net/360video/test.webp',
      (loadedTexture) => {
        setTexture(loadedTexture);
      }
    );
  }, []);  
  

  const [currentApp, setCurrentApp] = useState("first-call");
  const [isAnimating, setIsAnimating] = useState(false);
  const [battery, setBattery] = useState(92); // バッテリー残量のモックデータ

  const handleEndCall = () => {
    setCurrentApp(null); // 電話画面を消す
  };

  
  ///カメラアプリコンポーネント
  const cameraClick = (app) => {
    setIsAnimating(true);
    setTimeout(() => {
      if (app.id === 'camera') {
        setCurrentApp('camera');
      } else {
        setCurrentApp(app.id);
      }
      setIsAnimating(false);
    }, 300);
  };

  // 音声波形
  // const [audioStream, setAudioStream] = useState(null);
  // const canvasRef = useRef(null);
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const canvasCtx = canvas.getContext("2d");
  //   canvas.width = 1600;
  //   canvas.height = 500;
  
  //   const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  //   const analyser = audioContext.createAnalyser();
  //   analyser.fftSize = FFT_SIZE;
  //   const bufferLength = analyser.frequencyBinCount;
  //   const dataArray = new Uint8Array(bufferLength);
  
  //   let source;
  //   if (audioStream) {
  //     source = audioContext.createMediaStreamSource(audioStream);
  //     source.connect(analyser);
  //   }
  
  //   let phase = 0;
  //   let blendFactor = audioStream ? 1 : 0; // 初期状態で1に設定
  
  //   const draw = () => {
  //     requestAnimationFrame(draw);
  
  //     if (audioStream) {
  //       // 音声データがある間はblendFactorを1に保つ
  //       blendFactor = Math.min(blendFactor + 1, 1); // 増加速度も少し速めに
  //       analyser.getByteFrequencyData(dataArray);
  //     } else {
  //       // 音声データがない場合はblendFactorを速めに減少
  //       blendFactor = Math.max(blendFactor - 1, 1); // 減少速度を速めに
  //       for (let i = 0; i < bufferLength; i++) {
  //         dataArray[i] = 90 + 50 * Math.sin((i / 20) + phase);
  //       }
  //       phase += 0.02;
  //     }
  
  //     // 実データとダミーデータをblendFactorでブレンド
  //     const blendedDataArray = dataArray.map((val, i) => {
  //       const dummyValue = 90 + 50 * Math.sin((i / 20) + phase);
  //       return dummyValue * (1 - blendFactor) + val * blendFactor;
  //     });
  
  //     canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  
  //     const barWidth = (canvas.width / bufferLength) * 15;
  //     let x = 0;
  
  //     for (let i = 0; i < bufferLength; i++) {
  //       const barHeight = blendedDataArray[i];
  //       canvasCtx.fillStyle = `rgb(${27 + barHeight / 6}, ${68 + barHeight / 12}, ${75 + barHeight / 8})`;
  //       canvasCtx.fillRect(
  //         x,
  //         canvas.height - barHeight * (canvas.height / 250),
  //         barWidth,
  //         barHeight * (canvas.height / 150)
  //       );
  //       x += barWidth + 1;
  //     }
  //   };
  
  //   draw();
  
  //   return () => {
  //     audioContext.close();
  //   };
  // }, [audioStream]);
  
  
  // handlePhotoSelectedを定義
  const handlePhotoSelected = (photo) => {
    console.log('Photo selected:', photo); // 選択された写真を確認
   };

 
  const AppClick = (app) => {
    setIsAnimating(true);
    setTimeout(() => {
      if (app.id === 'under') {
        setCurrentApp('match');
      } else if (app.id === 'camera') {
        setCurrentApp('camera');
      } else if (app.id === 'calender') {
        setCurrentApp('calender');
      } else if (app.id === 'bank') {
        setCurrentApp('bank');
      } else if (app.id === 'youtube-ST') {
        setCurrentApp('youtube-ST');
      } else if (app.id === 'google-map') {
        setCurrentApp('google-map');
      } else {
        setCurrentApp(app.id);
      }
      setIsAnimating(false);
    }, 300);
  };

  const handleHomeClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  // テクスチャがまだ無い場合は「Loading...」を返す
  if (!texture) {
    return <div>Loading 360 Image...</div>
  }  

  return (


    <div className="App">
       <header className="App-header">          
       <Canvas style={{ width: '100%', height: '100%' }}>
          <OrbitControls enabled={!isInteracting} /> {/* スマホ操作中は無効化 */}

          {/* 360度背景 */}
          <mesh scale={[-1, 1, 1]}> {/* X軸方向で反転 */}
            <sphereGeometry args={[50, 64, 64]} />
            <meshBasicMaterial
              map={texture}
              side={THREE.BackSide}
              transparent={true} // 透過情報を有効化
              alphaTest={0.5} // アルファテストを設定（透過のしきい値）
            />
          </mesh>

          {/* phone-master固定 */}
          {/* <Html transform portal={false} position={[30, -30, 0]} rotation={[42, 0, -20]}> */}
          <Html transform portal={false} position={[0, 10, -10]} rotation={[0, 0, 0]}>
          <AppProvider>
          <UnderMessageProvider>  
          <ObaAssistant />

          <div className="login-info">
            {user ? (
              <>
                <h3>{user.username}がログイン中</h3>
                <button onClick={signOut}>ログアウト</button>
              </>
            ) : (
              <h3>権限がありません</h3>
            )}
          </div>
            {/* <div className="phone-cover">
              <img
                src={`${process.env.PUBLIC_URL}/images/HijitsuPhoneHack.png`}
                alt="Hijitsu Phone Hack"
                className="responsive-background"
              />
            </div> */}
          <div className={`phone-master ${isAnimating ? 'slide-up' : ''}`}
                    onPointerDown={() => {
                      console.log("Pointer Down");
                      setIsInteracting(true);
                    }}
                    onPointerUp={() => {
                      console.log("Pointer Up");
                      setIsInteracting(false);
                    }}>
            <div className="phone-mainframe"></div>
            <div className="phone">
            <div className="status-bar">
              <div className="right-icons">
              <span className="wifi-icon"><WifiIcon /></span>
                <span className="battery-icon">
                  {battery}% <BatteryIcon level={battery} />
                </span>
              </div>
            </div>
  
              {/* 最初の電話かかってくる */}
              {currentApp === "first-call" && <FirstCalling onEndCall={handleEndCall} />}
              
              {currentApp !== 'calender' && <CalendarWidget AppClick={AppClick} />}
              {currentApp === 'calender' && <Calender setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />} 

              <div className="applications">
                {applications.map(app => (
                  <div key={app.id} className="app-container" onClick={() => AppClick(app)}>
                    <button className={`app-icon ${app.id}`}>
                      <img src={app.icon} alt={app.name} style={{ width: '100%', height: '100%' }} />
                    </button>
                    <span className="app-name">{app.name}</span>
                  </div>
                ))}
              </div>
              {currentApp === 'match' && <Match setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'match-id' && <MatchID setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'match-swipe' && <MatchSwipe setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'match-chat' && <MatchChat setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'camera' && (
              // <Camera setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} onAudioStream={setAudioStream} />
              <Camera setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />
              )}
              {currentApp === 'bank' && <BankApp setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />} 
              {currentApp === 'youtube-ST' && <YouTubeStudio setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'photo' && (
                  <PhotoApp
                    setCurrentApp={setCurrentApp}
                    handleHomeClick={handleHomeClick}
                    handlePhotoSelected={handlePhotoSelected} // handlePhotoSelectedをPhotoAppに渡す
                  />
                )}
              
              {currentApp !== 'google-map' && <GoogleMapWidget AppClick={AppClick} />}
              {currentApp === 'google-map' && <MapApp handleHomeClick={handleHomeClick} setCurrentApp={setCurrentApp} />}


              <div className="phone-dock">
                <button
                  className="dock-icon"
                  onClick={() => AppClick({ id: 'camera' })}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/camera-icon.png`}
                    alt="カメラ"
                    className="dock-icon-img"
                  />
                </button>
              </div>
            </div>  
          </div>         
          {/* <canvas ref={canvasRef} className="wave-visualizer" /> */}
              </UnderMessageProvider>
              </AppProvider>
          </Html>
       </Canvas>
      </header>
    </div>

);
}

export default App;