import React, { useState, useEffect, useContext, useRef } from 'react';
import './photo.css';
import { AppContext } from './MatchApp/AppContext.js';

const PhotoApp = ({ setCurrentApp, handleHomeClick, handlePhotoSelected }) => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const { fromMatchChat, setFromMatchChat } = useContext(AppContext);
  const videoRef = useRef(null);

  useEffect(() => {
    fetchPhotos();
  }, []);

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
  
    setVh();
    window.addEventListener('resize', setVh);
  
    return () => window.removeEventListener('resize', setVh);
  }, []);

  const fetchPhotos = async () => {
    try {
      const response = await fetch(`https://pixabay.com/api/?key=${process.env.REACT_APP_PIXABAY_API_KEY}&q=landscape&image_type=photo&per_page=30`);
      const data = await response.json();
  
      const storedPhotos = JSON.parse(localStorage.getItem('photos')) || [];
      const allPhotos = [...data.hits, ...storedPhotos];
  
      const dates = [
        '2023/10/31','2023/11/07','2023/12/29',
        '2024/01/08', '2024/04/20', '2024/05/05', '2024/05/18', '2024/06/02',
        '2024/06/15', '2024/07/07', '2024/08/10', '2024/08/25',
        '2024/09/05', '2024/09/20', '2024/10/01', '2024/11/07', '2024/11/17',
      ];
  
      // 各写真に日付を設定
      allPhotos.forEach((photo, index) => {
        if (photo.id === 'drivers-license' && !photo.date) {
          const today = new Date().toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' });
          photo.date = today;
        } else {
          const date = dates[index % dates.length];
          photo.date = date;
        }
        
        // `largeImageURL` を設定
        photo.largeImageURL = photo.largeImageURL || photo.previewURL; // サムネイルがない場合はデフォルトで `previewURL` を利用
      });
  
      // なりすまし用画像
      const catfishImages = [
        { id: 'catfish1', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish1.jpg`, date: '2024/01/08' },
        { id: 'catfish2', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish2.jpg`, date: '2024/01/08' },
        { id: 'catfish3', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish3.jpg`, date: '2024/01/08' },
        { id: 'catfish4', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish4.jpg`, date: '2024/01/08' },
        { id: 'catfish5', previewURL: `${process.env.PUBLIC_URL}/images/catfish/Catfish5.jpg`, date: '2024/01/08' }
      ];
  
      // 写真を日付ごとにグループ化
      const groupedPhotos = allPhotos.reduce((acc, photo) => {
        const date = photo.date;
        if (!acc[date]) acc[date] = [];
        acc[date].push(photo);
        return acc;
      }, {});

      // 10/31の日付に「 」を追加
      const insta_story_memory = {
        id: 'cheese-mazesoba',
        previewURL: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/insta_story_fathermemory_2.webp',
        date: '2023/10/31'
      };
      groupedPhotos['2023/10/31'] = [...(groupedPhotos['2023/10/31'] || []), insta_story_memory];      
  
      // 2023/11/07の日付に「チーズまぜそば」を追加
      const cheeseMazesoba = {
        id: 'cheese-mazesoba',
        previewURL: 'https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/チーズまぜそば.webp',
        date: '2023/11/07'
      };
      groupedPhotos['2023/11/07'] = [...(groupedPhotos['2023/11/07'] || []), cheeseMazesoba];

      // 2023/12/29の日付に「ピースサイン」を追加
      const Vsign = {
        id: 'V-sign',
        previewURL: `https://d15k62eom0hyt7.cloudfront.net/underSwipeWebP/Vsign.webp`, // ピースサインの画像
        date: '2023/12/29'
      };
      groupedPhotos['2023/12/29'] = [...(groupedPhotos['2023/12/29'] || []), Vsign];       

      // 2024/1/8の日付にcatfishImagesを追加
      groupedPhotos['2024/01/08'] = [...(groupedPhotos['2024/01/08'] || []), ...catfishImages];      

      // 10/29の日付に追加する動画サムネイル
      const videoThumbnail1 = {
        id: 'Vlog1',
        previewURL: `https://d15k62eom0hyt7.cloudfront.net/vlog/Vlog1_2.mp4`,
        isVideo: true,
        videoDuration: '1:30', // 秒数を設定
        date: '2024/11/17'
      };

      const videoThumbnail2 = {
        id: 'Vlog2',
        previewURL: `https://d15k62eom0hyt7.cloudfront.net/vlog/Vlog2_2.mp4`,
        isVideo: true,
        videoDuration: '2:12', // 秒数を設定
        date: '2024/11/17'
      };      

      // 11/07の日付に「やりたいことリスト」を追加
      const bucketList = {
        id: 'bucket-list',
        previewURL: `${process.env.PUBLIC_URL}/images/やりたいことリスト.jpg`, // やりたいことリストの画像
        date: '2024/11/07'
      };
      groupedPhotos['2024/11/07'] = [...(groupedPhotos['2024/11/07'] || []), bucketList];
      
  
      groupedPhotos['2024/11/17'] = [...(groupedPhotos['2024/11/17'] || []), videoThumbnail1, videoThumbnail2];
  
      setPhotos(groupedPhotos);
    } catch (error) {
      console.error('Error fetching photos:', error);
    }
  };
  
  const handlePhotoClick = (photo) => {
    if (fromMatchChat) {
      setSelectedPhoto(photo);
      setFromMatchChat(false);
      handlePhotoSelected(photo);
      setCurrentApp('match-chat');
    } else {
      if (photo.isVideo) {
        setSelectedPhoto({ type: 'video', src: photo.previewURL }); // 動画の場合
      } else {
        setSelectedPhoto({
          type: 'photo',
          src: photo.largeImageURL || photo.previewURL // largeImageURLがない場合はpreviewURLを使用
        });
      }
    }
  };
  

  const closeFullscreen = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // 動画を停止
      videoRef.current.currentTime = 0;
    }
    setSelectedPhoto(null);
  };

  const handleClick = () => { 
    setIsAnimating(true);
    setTimeout(() => {
      handleHomeClick();
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  return (
    <div className={`PhotoApp-wrapper ${isAnimating ? 'PhotoApp-homeback' : ''}`}>
      <div className="PhotoApp-container">
      {selectedPhoto ? (
    selectedPhoto.type === 'video' ? (
        <div className="photo-fullscreen">
            <button className="close-button" onClick={closeFullscreen}>×</button>
            <video controls src={selectedPhoto.src} ref={videoRef} className="video-player" />
        </div>
    ) : selectedPhoto.type === 'photo' ? (
        <div className="photo-fullscreen">
            <button className="close-button" onClick={closeFullscreen}>×</button>
            <img src={selectedPhoto.src} alt="Selected content" />
        </div>
    ) : (
        <div className="photo-fullscreen">
            <button className="close-button" onClick={closeFullscreen}>×</button>
            <p>不明なコンテンツです</p>
        </div>
    )
) : (
          <div className="photo-date-groups">
          {Object.keys(photos).reverse().map(date => (  // 並び順を逆に
            <div key={date} className="photo-date-group">
              <h2 className="photo-date">{date}</h2>
              <div className="photo-grid">
                {photos[date].map((photo) => (
                    <div
                      key={photo.id}
                      className="photo-item"
                      onClick={() => handlePhotoClick(photo)}
                    >
                      {photo.isVideo ? (
                        <div className="video-thumbnail">
                          <h3>{photo.id}</h3>
                          <span className="video-duration">{photo.videoDuration}</span>
                        </div>
                      ) : (
                        <img src={photo.previewURL} alt={photo.tags} />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
          </div>
        )}
      </div>
      <div className="photo-footer">
        <button className="home-button" onPointerDown={handleClick}></button>
      </div>
    </div>
  );
};

export default PhotoApp;